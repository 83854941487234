<template>
  <div class="breadcrumb">
    <div class="breadcrumb-container">
      <span v-for="(value, index) in list" :key="index">
        <span v-if="index === 0">
          <i class="fa fa-circle" aria-hidden="true"></i>
          {{ value }}
        </span>
        <span v-else>
          <i class="fa fa-chevron-right" aria-hidden="true"></i>
          {{ value }}
        </span>
      </span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Breadcrumb",
  props: {
    list: {
      type: Array,
      required: true,
    },
  },
});
</script>
