<template>
  <section class="hero-page">
    <div class="hero-container">
      <div class="column">
        <h1>{{ title }}</h1>
        <p>{{ description }}</p>
      </div>
    </div>
  </section>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "HeroPage",
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: false,
    },
  },
});
</script>
