<template>
  <div :class="{ 'button-group': group }">
    <button
      :type="type"
      :class="[
        'button',
        size ? 'button-' + size : '',
        rounded ? 'button-rounded' : '',
        icon ? 'button-icon' : '',
        'text-' + color,
        'bg-' + background,
        colorHover ? 'hover:text-' + colorHover : '',
        backgroundHover ? 'hover:bg-' + backgroundHover : '',
        shadown ? 'button-shadown' : '',
        block ? 'button-block' : '',
      ]"
      :disabled="disabled"
      @click="action"
    >
      <slot></slot>
    </button>
  </div>
</template>

<script>
import Vue from "vue";

export default Vue.extend({
  name: "Button",
  props: {
    group: {
      type: Boolean,
      required: false,
      default: false,
    },
    type: {
      type: String,
      required: false,
      default: "button",
    },
    rounded: {
      type: Boolean,
      required: false,
      default: false,
    },
    icon: {
      type: Boolean,
      required: false,
      default: false,
    },
    size: {
      type: String,
      required: false,
      default: "",
    },
    color: {
      type: String,
      required: true,
    },
    colorHover: {
      type: String,
      required: false,
      default: "",
    },
    background: {
      type: String,
      required: true,
    },
    backgroundHover: {
      type: String,
      required: false,
      default: "",
    },
    shadown: {
      type: Boolean,
      require: false,
      default: true,
    },
    block: {
      type: Boolean,
      require: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      require: false,
      default: false,
    },
  },
  methods: {
    action() {
      this.$emit("action");
    },
  },
});
</script>
