<template>
  <Layout>
    <div class="section-content">
      <div class="form">
        <div class="form-heading text-center mb-6">
          <h5>{{ title }}</h5>
          <p>
            {{ description }}
          </p>
        </div>
        <div class="flex flex-col sm:flex-row justify-center items-center">
          <Button
            color="white"
            background="gray-800"
            background-hover="gray-700"
            size="large"
            :rounded="true"
            :group="true"
            @action="toLogin"
          >
            Já sou inscrito
          </Button>
          <Button
            v-if="!esconderBotaoCriarInscricao"
            color="white"
            background="lime-600"
            background-hover="lime-700"
            size="large"
            :rounded="true"
            :group="true"
            @action="toRegister"
          >
            Criar inscrição
          </Button>
        </div>
      </div>
    </div>
  </Layout>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import Layout from "@/components/layout/subscription/Layout.vue";
import Button from "@/components/elements/Button.vue";

export default Vue.extend({
  name: "Subscription",
  data() {
    return {
      esconderBotaoCriarInscricao: false
    }
  },
  components: {
    Layout,
    Button,
  },
  computed: {
    ...mapGetters("eleicaonet", ["getParamHotsite"]),
    title() {
      if (
        this.getParamHotsite.pages.subscription &&
        this.getParamHotsite.pages.subscription.main &&
        this.getParamHotsite.pages.subscription.main.title
      ) {
        return this.getParamHotsite.pages.subscription.main.title
      } else {
        return "Conselho Deliberativo ou Diretoria"
      }
    },
    description() {
      if (
        this.getParamHotsite.pages.subscription &&
        this.getParamHotsite.pages.subscription.main &&
        this.getParamHotsite.pages.subscription.main.description
      ) {
        return this.getParamHotsite.pages.subscription.main.description
      } else {
        return `Para efetuar uma nova inscrição clique no botão criar inscrição,
            para consultar sua inscrição clique em já sou inscrito`
      }

    }
  },
  mounted() {
    if (this.getParamHotsite.pages.subscription) {
      this.esconderBotaoCriarInscricao = this.getParamHotsite.pages.subscription.esconderBotaoCriarInscricao || false
    }
  },
  methods: {
    toLogin() {
      this.$router.push({ name: "SubscriptionLogin" });
    },
    toRegister() {
      this.$router.push({ name: "SubscriptionRegister" });
    },
  },
});
</script>
