var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:{ 'button-group': _vm.group }},[_c('button',{class:[
      'button',
      _vm.size ? 'button-' + _vm.size : '',
      _vm.rounded ? 'button-rounded' : '',
      _vm.icon ? 'button-icon' : '',
      'text-' + _vm.color,
      'bg-' + _vm.background,
      _vm.colorHover ? 'hover:text-' + _vm.colorHover : '',
      _vm.backgroundHover ? 'hover:bg-' + _vm.backgroundHover : '',
      _vm.shadown ? 'button-shadown' : '',
      _vm.block ? 'button-block' : '' ],attrs:{"type":_vm.type,"disabled":_vm.disabled},on:{"click":_vm.action}},[_vm._t("default")],2)])}
var staticRenderFns = []

export { render, staticRenderFns }