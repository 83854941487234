<template>
  <main>
    <HeroPage
      :title="title"
      :description="description"
    ></HeroPage>
    <Breadcrumb :list="breadcrumbList"></Breadcrumb>
    <section>
      <div class="section-container">
        <slot></slot>
      </div>
    </section>
  </main>
</template>

<script>
import Vue from "vue";

import HeroPage from "@/components/HeroPage.vue";
import Breadcrumb from "@/components/elements/Breadcrumb.vue";
import { mapGetters } from "vuex";

export default Vue.extend({
  name: "Layout",
  components: {
    HeroPage,
    Breadcrumb,
  },
  data() {
    return {
      title: "Inscrições",
      description: "Inscrições de chapas e candidatos",
      breadcrumbList: [],
    };
  },
  computed: {
    ...mapGetters("eleicaonet", ["getParamHotsite"]),
  },
  mounted() {
    this.breadcrumbList = this.$route.meta.breadcrumb;
    if (this.getParamHotsite.pages.subscription && this.getParamHotsite.pages.subscription.layout) {
      if (this.getParamHotsite.pages.subscription.layout.title) {
        this.title = this.getParamHotsite.pages.subscription.layout.title
      }
      if (this.getParamHotsite.pages.subscription.layout.description) {
        this.description = this.getParamHotsite.pages.subscription.layout.description
      }
    }
  },
});
</script>
